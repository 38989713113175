/* src/components/skills.css */

/* Section Styling */
.skills-section {
  padding: 3rem 1.5rem;
  background: linear-gradient(110deg, #cf711f, #e79b29);
  color: #333;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  padding: 0 1rem; /* Added padding to prevent content from touching edges */
}

/* Title Styling */
h2 {
  font-size: 2.5rem;
  font-weight: 700;
  color: #fff;
  margin-bottom: 2rem;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
}

/* Grid Layout for Skill Categories */
.skill-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem; /* Adjusted for spacing between items */
}

/* Skill Categories */
.skill-category {
  background: #ffffff;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.3s ease;
  position: relative;
}

.skill-category::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  transition: opacity 0.3s ease;
  opacity: 0;
  z-index: 0;
}

.skill-category:hover::before {
  opacity: 1;
}

.category-title {
  font-size: 2rem;
  font-weight: 600;
  color: #cf711f;
  margin-bottom: 1rem;
  text-transform: uppercase;
  border-bottom: 2px solid #cf711f;
  display: inline-block;
  padding-bottom: 0.5rem;
  position: relative;
  z-index: 1; /* Ensure title appears above the overlay */
}

/* Skills List within Each Category */
.skills-list {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

.skill-item {
  background: #ffffff;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease,
    background-color 0.3s ease;
  cursor: pointer;
  flex: 1 1 150px;
  max-width: 200px;
  text-align: center;
  position: relative;
}

.skill-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  transition: opacity 0.3s ease;
  opacity: 0;
  z-index: 0;
}

.skill-item:hover::before {
  opacity: 1;
}

.skill-item:hover {
  transform: scale(1.05); /* Slightly enlarge on hover */
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  background-color: #e0f7fa; /* Light blue background on hover */
}

.skill-icon {
  font-size: 3rem; /* Larger icon for emphasis */
  color: #333; /* Default color */
  transition: transform 0.3s ease, color 0.3s ease;
  z-index: 1; /* Ensure icon appears above the overlay */
}

/* Professional Colors for Icons */
.skill-item .fa-js {
  color: #f7df1e;
} /* JavaScript Yellow */
.skill-item .fa-react {
  color: #61dafb;
} /* React Blue */
.skill-item .fa-html5 {
  color: #e34f26;
} /* HTML5 Red */
.skill-item .fa-css3-alt {
  color: #1572b6;
} /* CSS3 Blue */
.skill-item .fa-node-js {
  color: #8cc84b;
} /* Node.js Green */
.skill-item .fa-github {
  color: #f1502f;
} /* Git Red */
.skill-item .fa-docker {
  color: #2496ed;
} /* Docker Blue */
.skill-item .fa-aws {
  color: #ff9900;
} /* AWS Orange */
.skill-item .fa-s3 {
  color: #569a31;
} /* S3 Green */
.skill-item .fa-lambda {
  color: #ff9900;
} /* Lambda Orange */
.skill-item .fa-database {
  color: #003b57;
} /* Database Blue */

.skill-item:hover .skill-icon {
  transform: scale(1.2); /* Enlarge icon on hover */
  color: #000; /* Default color on hover (or keep individual colors if preferred) */
}

.skill-name {
  font-size: 1.2rem; /* Adjusted size for balance */
  font-weight: 500;
  color: #333;
  margin-top: 0.5rem;
  z-index: 1; /* Ensure text appears above the overlay */
}

/* Responsive Design */
@media (max-width: 1200px) {
  .container {
    padding: 0 1rem;
  }
}

@media (max-width: 900px) {
  h2 {
    font-size: 2rem;
  }

  .category-title {
    font-size: 1.5rem;
  }
}

@media (max-width: 600px) {
  .skill-grid {
    grid-template-columns: 1fr; /* Single column layout on small screens */
  }

  .skills-list {
    flex-direction: column;
    align-items: center;
  }

  .skill-item {
    width: 100%;
    max-width: 300px;
  }

  h2 {
    font-size: 1.5rem;
  }

  .category-title {
    font-size: 1.25rem;
  }
}
