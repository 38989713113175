@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");

* {
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
}
body {
  font-family: "Rubik", sans-serif;
}
