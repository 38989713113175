/* General Styles */
.main {
  font-family: sans-serif;
  line-height: 1.5;
  font-weight: 400;
  text-align: center; /* Center-align text by default */
}

/* Typewriter Effect */
.typewriter {
  overflow: hidden;
  border-right: 0.15em solid #e67e22;
  white-space: nowrap;
  margin: 0 auto;
  letter-spacing: 0.15em;
  animation: typing 4s steps(40, end), blink-caret 0.75s step-end infinite;
  text-align: center; /* Ensure text is centered */
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: #e67e22;
  }
}

/* Hero Section */
.section-hero {
  background-color: #fdf2e9;
  padding: 6rem 2rem; /* Adjusted for better spacing on small screens */
  width: 100%;
  box-sizing: border-box;
}

.hero {
  max-width: 130rem;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr;
  gap: 4rem;
  align-items: center;
  text-align: center; /* Center-align text */
}

.hero-img {
  width: 100%;
  max-width: 100%;
  height: auto; /* Maintain aspect ratio */
  object-fit: cover; /* Ensure image covers the container */
  border-radius: 50px;
  transition: transform 0.4s ease-in-out, box-shadow 0.4s ease-in-out; /* Smooth and noticeable animations */
}

.hero-img:hover {
  transform: scale(1.1); /* Slight zoom effect */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Adds depth effect */
}

.heading-primary {
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.2;
  color: #e67e22;
  letter-spacing: -0.5px;
  margin-bottom: 2.4rem;
  text-align: center; /* Center-align heading text */
}

.hero-description {
  font-size: 1.6rem;
  line-height: 2.2rem;
  margin-bottom: 3.2rem;
  text-align: justify; /* Center-align description text */
}

/* Buttons */
.btn {
  display: inline-block;
  text-decoration: none;
  font-size: 1.6rem;
  font-weight: 600;
  padding: 1.2rem 2.4rem;
  border-radius: 9.5px;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s; /* Added transitions for hover effects */
  text-align: center; /* Center-align button text */
}

.btn--full {
  background-color: #e67e22;
  color: #fff;
}

.btn--full:hover,
.btn--full:active {
  background-color: #cf711f;
  transform: scale(1.05); /* Slight zoom effect */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adds depth effect */
}

.btn--outline {
  background-color: #fff;
  color: #555;
}

.btn--outline:hover,
.btn--outline:active {
  background-color: #fdf2e9;
  box-shadow: inset 0 0 0 3px #fff;
  transform: scale(1.05); /* Slight zoom effect */
}

/* Margin Helper */
.margin-right-sm {
  margin-right: 1.6rem !important;
}

/* Responsive Design */

/* Medium Devices (Tablets) */
@media (min-width: 601px) and (max-width: 900px) {
  .hero {
    grid-template-columns: 1fr 1fr; /* Two columns layout */
    gap: 4rem; /* Adjust gap to ensure space */
  }

  .hero-img {
    max-width: 100%;
    width: 100%; /* Ensure image scales properly */
    height: auto; /* Maintain aspect ratio */
    object-fit: cover; /* Ensure image covers the container */
  }

  .heading-primary {
    font-size: 4rem;
    margin-bottom: 3.2rem;
  }

  .hero-description {
    font-size: 1.8rem;
    line-height: 2.5rem;
    margin-bottom: 4.8rem;
  }

  .btn {
    font-size: 1.8rem;
    padding: 1.4rem 2.8rem;
  }
}

/* Large Devices (Desktops) */
@media (min-width: 901px) {
  .hero {
    grid-template-columns: 1fr 1fr;
    gap: 9.6rem;
  }

  .heading-primary {
    font-size: 5.2rem;
    margin-bottom: 3.2rem;
  }

  .hero-description {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 4.8rem;
  }
}

/* Small Devices (Mobile) */
@media (max-width: 600px) {
  .section-hero {
    padding: 6rem 1rem;
  }

  .hero-img {
    border-radius: 50px;
  }

  .heading-primary {
    font-size: 2.5rem;
    margin-bottom: 1.6rem;
  }

  .hero-description {
    font-size: 1.4rem;
    line-height: 1.8rem;
    margin-bottom: 2rem;
  }

  .btn {
    font-size: 1.4rem;
    padding: 1rem 2rem;
  }
}

/* Extra Small Devices (Very Small Screens) */
@media (max-width: 400px) {
  .hero {
    gap: 2rem; /* Reduce gap for very small screens */
  }

  .heading-primary {
    font-size: 2rem; /* Further reduce font size */
    margin-bottom: 1rem; /* Reduce bottom margin */
  }

  .hero-description {
    font-size: 1.2rem; /* Further reduce font size */
    line-height: 1.6rem; /* Adjust line height */
    margin-bottom: 1.5rem; /* Reduce bottom margin */
  }

  .btn {
    font-size: 1.2rem; /* Reduce button font size */
    padding: 0.8rem 1.6rem; /* Adjust button padding */
  }
}
